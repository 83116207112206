import Typography from '../../atoms/typography'
import BHLink from '../../molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo'].includes(prop)
}

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		max-width: 500px;
		padding: 0;
	}
`

const Title = styled(Typography)`
	${({ theme }) => theme.typography.headlineBig};
`

const Subtitle = styled(Typography)`
	${({ theme }) => theme.typography.headlinedescription};
	margin-top: ${({ theme }) => theme.spacing(2)};
`
const Description = styled('div')`
	${({ theme }) => theme.typography.headlinedescription};
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? '16px' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
		margin-top: ${({ hasSiblings }) => (hasSiblings ? '24px' : '0')};
	}

	.MuiButton-text {
		color: inherit;
		min-width: 0;
		width: auto;

		&::after {
			background: inherit;
		}
		&:hover,
		&:focus,
		&:active {
			color: inherit;
		}
	}
`

const Link = styled(BHLink)`
	width: 100%;

	&:after {
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.4) 0%,
			rgba(0, 0, 0, 0) 25%,
			rgba(0, 0, 0, 0) 57%,
			rgba(0, 0, 0, 0.28) 71.5%,
			rgba(0, 0, 0, 0.4) 100%
		);
	}
`

const Button = styled(BHButton)`
	width: 100%;
	${({ theme }) => theme.breakpoints.up('md')} {
		width: auto;
		min-width: 280px;
	}
`

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button }
